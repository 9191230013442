import Request from '@/utils/request';

//图片上传至阿里云
export function getUploadToken() {
  return Request('get', '/api/common/upload/index')
}

//图片fileUrl转fileId
export function getFileId(staticServerUrl) {
  let data = { staticServerUrl }
  return Request('post', '/api/common/upload/save', data)
}